.font-17px {
  font-size: 17px;
}

.news-text {
  font-weight: 300;
  a {
    text-decoration: underline;
  }
}

.modal-backdrop {
  bottom: 0!important;
  top: 50px!important;
  height: 100vh!important;
  z-index: 1005!important;
  max-width: 500px;
  margin-left: calc(50% - 250px);
}

.modal-dialog {
  top: 64px!important;
  margin: 20px 20px 20px 20px;
  .modal-header {
    border-bottom: none!important;
    padding:0;
    .close {
      width: 25px!important;
      height: 25px!important;
      box-shadow: 1px 2px 3px 2px rgba(0, 0, 0, .5)!important;
      padding: 0px 0px 0px 0px!important;
      margin: 0px 0px 0px 0px!important;
      font-size: 17px!important;
      font-weight: 500!important;
      font-stretch: condensed!important;
      line-height: 17px!important;
      color: #fff!important;
      opacity: 1!important;
    }
    button.close {
      position:absolute;
      top:-10px;
      left:-10px;
      padding: 0!important;
      background-color: #000!important;
      border: 2px solid #fff!important;
      border-radius: 50%!important;
    }
  }
  .modal-body {
    text-align: left !important;
    max-height: calc(100vh - 123px);
    font-size: 14px;
    a {
      text-decoration: underline;
    }
    .ps__rail-y {
      width: 5px;
      .ps__thumb-y {
        border-radius: 2px;
        width: 3px;
        right: 0px;
      }
    }
  }
}

.infospopup-scrollbar {
  max-height:calc(100vh - 139px);
}

.modal .modal-dialog.modal-top-left {
margin-left: calc(50% - 210px);
margin-top: 0px !important;
top: 70px!important;
}

.modal-side {
  width: 460px !important;
}

.modal-padding {
  padding: 8px;
}

@media (max-width: 325px) {
  .modal .modal-dialog.modal-top-left {
    margin-left: 20px;
  }
  .modal-backdrop {
    margin-left: 0;
  }
  .modal-side {
    width: calc(100% - 40px) !important;
  }  
  .modal-dialog {
    .modal-body {
      max-height: calc(100vh - 90px);
    }
  }
  .infospopup-scrollbar {
    max-height:calc(100vh - 100px);
  }
}

@media (min-width: 325.02px) and (max-width: 389px) {
  .modal .modal-dialog.modal-top-left {
    margin-left: 20px;
  }
  .modal-backdrop {
    margin-left: 0;
  }
  .modal-side {
    width: calc(100% - 40px) !important;
  }  
  .modal-dialog {
    .modal-body {
      max-height: calc(100vh - 104px);
    }
  }
  .infospopup-scrollbar {
    max-height:calc(100vh - 120px);
  }
}

@media (min-width: 389.02px) and (max-width: 499.98px) {
  .modal .modal-dialog.modal-top-left {
    margin-left: 20px;
  }
  .modal-backdrop {
    margin-left: 0;
  }
  .modal-side {
    width: calc(100% - 40px) !important;
  }
}

@media (min-width: 500px) {
  .modal-dialog {
    max-width: 460px;
    margin: 1.75rem auto;
  }
  .modal .modal-dialog.modal-top-left {
    margin-left: calc(50% - 200px);
    left: -30px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) and (max-width: 1499.98px) {
}

@import '../colors.scss';

.newspagebodywrapper {
	height: 100vh;
	max-width: 100vw;
	//min-height: 100vh;
	padding-top: 50px;
}

.resultpagebodywrapper {
	height: calc(100vh - 125px);
	max-width: 100vw;
	//min-height: 100vh;
	padding-top: 0;
	top: 50px;
	width: min(100%, 500px);
	position: fixed;
}

.searchpagebodywrapper {
	height: calc(100vh - 50px);
	max-width: 100vw;
	//min-height: 100vh;
	padding-top: 0;
	top: 50px;
	width: min(100%, 500px);
	position: fixed;
}

.bodywrapperbackground {
	background-color: $color-blue;
	background-position: top center;
}

.w100 {
	width: 100%;
}
@media (max-width: 325px) {
	.resultpagebodywrapper {
		min-width: 100vw;
	}
}

@media (max-width: 500px) {
	.newspagebodywrapper {
		min-width: 100vw;
	}
	
	.resultpagebodywrapper {
		min-width: 100vw;
	}
	
	.searchpagebodywrapper {
		min-width: 100vw;
	}
}

@media (min-width: 325.02px) and (max-width: 395px){

}

@media (max-width: 575.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) and (max-width: 1499.98px) {
}

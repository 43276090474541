.px-d1 {
  padding-left: 2.13rem;
  padding-right: 2.13rem;
}

.search-footer {
  z-index: 999;
  height: 179px;
  width: 100%;
  max-width: 500px;
  background-color: $color-yellow;
  position: fixed;
  bottom:0;
  font-size: 26px;
}

.embarquement-immediat {
  z-index: 999;
  height: 225px;
  width: 100%;
  max-width: 500px;
  background-color: $color-yellow;
  position: fixed;
  bottom:0;
  font-size: 26px;
  .embarquement-immediat-text {
    padding-top: 32px;
    p {
      margin-bottom: 15px;
    }
  }
}

.search-body {
  z-index: 999;
  width: 100%;
  max-width: 500px;

}
.boat-picker-text {
  font-style: italic;
  font-size: 18px;
  font-weight: 300;
}
.pier-picker-text {
  font-style: normal;
  font-weight: 300;
}
.uszogumi-text {
  position: absolute;
  top: 54px;
  left: 53%;
  transform: translate(-50%, -50%);
  z-index: 999;
  .uszogumi-text-a {
    color: $color-blue;
    font-size: 26px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    letter-spacing: -0.5px;
  }
  .uszogumi-text-b {
    color: $color-blue;
    font-size: 12px;
    line-height: 8px;
    text-align: center;
    letter-spacing: -0.5px;
  }

  width: 45px;
}
.uszogumi-text-4 {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  color: $color-blue;
  font-size: 15px;
  font-stretch: condensed;
  text-align: center;
  font-weight: bold;
  line-height: 15px;
  width: 45px;
  padding-top: 5px;
}
.uszogumi-text-3 {
  position: absolute;
  top: 46%;
  left: 53%;
  transform: translate(-50%, -50%);
  z-index: 999;
  color: $color-blue;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  font-weight: bold;
  font-stretch: condensed;
  text-align: center;
  width: 53px;
}

.uszogumi-text-5 {
  position: relative;
  top: -51%;
  left: 28%;
  //transform: translate(-50%, -50%);
  z-index: 999;
  color: $color-blue;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  font-weight: bold;
  text-align: center;
  width: 45px;
}

.uszogumi-text-2 {
  position: absolute;
  bottom: 37%;
  left: 45%;
  transform: translate(0%, 0%);
  z-index: 999;
  color: #919191;
  font-size: 9px;
  line-height: 16px;
  text-align: center;
  width: 45px;
}

.uszogumi-img {
  width: 115px;
  height: auto;
}
.uszogumi-img-2 {
  width: 100px;
  height: auto;
}

.side-nav {
  z-index: 1004!important;
  background-color: rgb(50, 70, 138)!important;
  width: 100%!important;
  max-width: 500px;
  height: 100vh!important;
  box-shadow: 0 0 0 0 rgba(0,0,0,0),0 0 0 rgba(0,0,0,0)!important;
  padding-top: 95px!important;
  padding-bottom: 0!important;


}
#sidenav-overlay {
  height: 100vh!important;
  max-width: 500px;
  margin-left: calc(50vw - 250px);
}
.side-slide-enter-done {
  transform: translateX(50vw) translateX(-250px)!important;
}

div.ScrollbarsCustom-Track:nth-child(2){
  width: 3px!important;
  border-radius: 1.5px!important;
}

.modal-shadow{
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, .6);
  z-index: 999;
  position: fixed;
  right: 0;
  bottom: 0;
  .pierItems-picker {
    height: 280px;
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1500;
    background-color: #fff;
    .boat-img{
      margin-top: -5px;
      width:auto;
      height: 36px;
    }
    .pierItems-picker-header {
      color: $color-blue!important;
      border-bottom: 1px solid $color-grey!important;
      padding: 0 .5rem;
      height: 40px;
      min-height: 1rem;
      line-height: 2rem;
      font-size: 1rem;
    }
    .m-style-picker {
      display: block;
      overflow: hidden;
      width: 100%;
      text-align: center;
      bottom: 0!important;
      height: 200px!important;
    }
    .m-style-picker-item {
      font-size: 21px;
      color: $color-black!important;
      height: 40px!important;
      line-height: 40px!important;
    }
    .m-style-picker-content-vertical {
      //transform: translate3d(0px, 0px, 0px)!important;
      //padding-top: 80.714px!important;
    }
    .m-style-picker-mask{
      height: 80px!important;
    }
    .m-style-picker-indicator-vertical {
      border-top: none!important;
      border-bottom: none!important;
      background-color: #EEEEEF!important;
      border-radius: 9px!important;
      height: 40px!important;
      left: 7px!important;
      width: calc(100% - 14px)!important;
      z-index: 0!important;
    }
    .pierItems-picker-button-left {
      background-color: #6a5e5e00!important;
      height: 19px;
      width: 43px;
      padding: 0;
      margin: 0;
      font-size: 1rem !important;
      line-height: 19px !important;
      color: rgb(50, 70, 138) !important;
      background-color: #c5e4ee !important;
    }
    .pierItems-picker-button-right {
      height: 19px;
      width: 43px;
      padding: 0;
      margin: 0;
      line-height: 19px !important;
      font-size: 1rem !important;
      color: #000;
      background-color: #cac7c7;

    }
  }
}

.datepicker {
  height: 280px!important;
  background-color: #fff!important;
  .datepicker-content {
     padding: 0!important;
     height: 240px!important;
  }
  .datepicker-viewport::after {
    background: linear-gradient(#fff, rgba(245, 245, 245, 0.1) 59%, rgba(245, 245, 245, 0.6) 60%, #fff) !important;
  }
  .datepicker-header {
    color: $color-blue!important;
    border-bottom: none!important;
    background: linear-gradient(180deg, $color-verticalpicker-header-top 0%, $color-verticalpicker-header-middlebottom 50%, $color-verticalpicker-header-middlebottom 100%);
    padding: 0 .5rem;
    height: 35px;
    min-height: 1rem;
    line-height: 2rem;
    font-size: 1rem!important;
  }
  .datepicker-viewport{
    height: 240px!important;
  }
  .datepicker-col-1 {
    margin: 0!important;
  }
  .datepicker-wheel{
    border-top: none!important;
    border-bottom: none!important;
    background-color: $color-light-grey !important;
    height: 40px!important;
    width: calc(100% - 7px)!important;
    z-index: 0!important;
  }
  div.datepicker-col-1:nth-child(2) > div:nth-child(1) > div:nth-child(1){
    border-radius: 0 9px 9px 0!important;
    right: 7px!important;
    text-align: center!important;
  }
  div.datepicker-col-1:nth-child(1) > div:nth-child(1) > div:nth-child(1){
    border-radius: 9px 0 0 9px !important;
    left: 7px!important;
    text-align: left!important;
    padding-left: calc(3rem + 2px);
  }
  .datepicker-scroll li {
    font-size: 21px!important;
    font-weight: 700!important;
    font-style: normal!important;

    font-family: "Futura"!important;
    font-stretch: condensed!important;
    color: rgb(0, 0, 0) !important;
  }
  .datepicker-navbar {
    height: 35px!important;
    border-top: nonef!important;
    //background: linear-gradient(0deg, #1E1D1D 0%, #BDB5B5 100%);
  }
  .datepicker-navbar-btn{
    font-size: 15px!important;
  }
  .datepicker-navbar-btn:nth-child(2) {
    position: absolute;
    z-index: 2;
    top: 7.5px;
    left: 15px;
    line-height: 19px;
    background-color: #6a5e5e00!important;
    height: 19px;
    width: 43px;
    padding: 0;
    margin: 0;
    font-family: "Futura";
    font-stretch: condensed;
    font-size: 15px;
    color: #000!important;
    font-weight: 400!important;
    background-color: #cac7c7 !important;
    border: 0!important;
    border-radius: .125rem!important;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.12),0 2px 10px 0 rgba(0,0,0,0.08)!important;
  }
  .datepicker-navbar-btn:nth-child(1) {
    position: absolute;
    z-index: 2;
    top: 7.5px;
    right: 15px;
    line-height: 19px;
    height: 19px;
    width: 43px;
    padding: 0;
    margin: 0;
    font-family: "Futura";
    font-stretch: condensed;
    font-size: 15px;
    color: $color-blue !important;
    background-color: #c5e4ee;
    border: 0!important;
    border-radius: .125rem!important;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)!important;
  }
}

.news-text {
  font-size: 16px;
}

.newsmodal {
  margin-left: calc(50% - 250px);
  width: min(100%, 500px);
  height: 100%;
  background-color: $color-blue;
  z-index: 1000;
  position: fixed;
  top: 49px;
  left: -4000px;
  transition: left 1s;
}

.newsmodal-visible {
  left: 0px;
  transition: left 1s;
}

@media (max-width: 499.98px) {
  .side-slide-enter-done {
    transform: translateX(0)!important;
  }
  #sidenav-overlay {
    margin-left: 0;
  }
  .newsmodal {
    margin-left: 0;
    min-width: 100vw;
  }
}

@media (max-width: 325px) {
  .datepicker {
    height: 229px!important;
    div.datepicker-col-1:nth-child(1) > div:nth-child(1) > div:nth-child(1){
      padding-left: 18px;
    }
    .datepicker-wheel {
      top: 39%!important;
    }
    .datepicker-viewport::after {
      background: linear-gradient(#fff, rgba(245, 245, 245, 0.1) 39%, rgba(245, 245, 245, 0.1) 41%, #fff 78%, #fff) !important
    }
    .datepicker-navbar-btn:nth-child(1) {
      top: 8px;
    }
    .datepicker-navbar-btn:nth-child(2) {
      top: 8px;
      
    }
  }
  .modal-shadow{
    .pierItems-picker {
      height: 229px;
    }
  }
  .search-footer {
    height: 120px;
  }
  .embarquement-immediat {
    height: 160px;
    .embarquement-immediat-text {
      font-size: 20px;
      padding-top: 12px;
      p {
        margin-bottom: 0;
      }
    }
  }
}

@media (min-width: 325.02px) and (max-width: 375px) {
  .datepicker {
    height: 229px!important;
  }
  .modal-shadow{
    .pierItems-picker {
      height: 229px;
    }
  }
  .search-footer {
    height: 120px;
  }
  .embarquement-immediat {
    height: 160px;
    .embarquement-immediat-text {
      font-size: 20px;
      padding-top: 12px;
      p {
        margin-bottom: 0;
      }
    }
  }
}

@media (min-width: 375.02px) and (max-width: 390px) {

}


@media (min-width: 576px) and (max-width: 1499.98px) {

}

/*@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) and (max-width: 1499.98px) {
}*/

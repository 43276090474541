/*.paddingleft-verticalpicker-item {
}

.paddingleft-verticalpicker-item {
}*/

.boat-verticalpicker-item-leftcol {
    padding-left: 35px;

}

.boat-verticalpicker-item-rightcol {
    padding-right: 35px;
    width: 190px;
}

@media (max-width: 325px) {
    .boat-verticalpicker-item-leftcol {
        padding-left: 18px;
        padding-right: 8px;
    }
    
    .boat-verticalpicker-item-rightcol {
        padding-left: 8px;
        padding-right: 18px;
    }
    
    /*.paddingleft-verticalpicker-item {
    }
    .paddingleft-verticalpicker-item {
    }*/
}

@media (min-width: 325.02px) and (max-width: 395px) {

}

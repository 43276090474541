.newsItem-card {
  background-color: $color-white;
  color: $color-black;
  border-radius: 8px;
  padding: 21px 5px 5px 11px;
  height: 356px;
  //margin-bottom: 235px;
  margin-top: 34px;
  .linie {
			box-sizing: border-box;
		  height: 1.5px;
		  width: 97%;
      margin: 0 2% 0 1%;
      //margin-left: 0;
      //margin-right: 0;
		  border-bottom: 1.5px solid $color-yellow-line;
			margin-top: 5px;
			margin-bottom: 10px;
	}
}

.news-card {
  background-color: $color-white;
  border-radius: 8px;
  margin: 30px 8px 15px 8px;
  padding: 8px;
  width: 100%;
  height: 75vh;
}


.newsdetails-title {
  font-size: 17px;
}
.newsdetails-content {
  font-size: 17px;
  font-weight: 300;
}
.newsdetails-date {
  font-size: 17px;
}

$font-size: 16px;
$line-height: 1.4;
$lines-to-show: 2;

.newsitem-content {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
  margin: 0 auto;
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.newsitem-title {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
  //margin: 0 auto;
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.newsitem-date {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-height: $font-size*$line-height; /* Fallback for non-webkit */
  //margin: 0 auto;
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 320px) {
  .newsItem-card {
    margin-top: 3vh;
  }
  .px-d1 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
}

@media (min-width: 320.02px) and (max-width: 389.98px) {
  .newsItem-card {
    margin-top: 3vh;
  }
  .px-d1 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
}

@media (min-height: 750px) {
  .newsItem-card {
    height: 450px;
  }
}


@media (max-width: 575.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) and (max-width: 1499.98px) {
}

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;700;800&display=swap');


html {
	height: 100vh;
}

body {
	min-height: 100vh;
	font-family: 'Futura';
	//font-family: futura-pt, sans-serif;
	//font-style: normal;
	font-weight: 500 !important;
  font-stretch: condensed;

	font-size: 15px;
	//font-weight: 400;
}

/*@font-face {
  font-family: 'Futura';
  src: url('../media/fonts/Futura-Bold.ttf') format('truetype');
  font-weight: bold;
}*/
@font-face {
  font-family: 'Futura';
  src: url('../media/fonts/Futura-CondensedExtraBold.ttf') format('truetype');
  font-weight: 900;
  font-stretch: condensed;
}
@font-face {
  font-family: 'Futura';
  src: url('../media/fonts/FuturaStd-CondensedBold.otf') format('truetype');
  font-weight: 700;
  font-stretch: condensed;
}
@font-face {
  font-family: 'Futura';
  src: url('../media/fonts/Futura-CondensedMedium.ttf') format('truetype');
  font-weight: 500;
  font-stretch: condensed;
}
@font-face {
  font-family: 'Futura';
  src: url('../media/fonts/FuturaStd-CondensedLight.otf') format('truetype');
  font-weight: 300;
  font-stretch: condensed;
}
/*@font-face {
  font-family: 'Futura';
  src: url('../media/fonts/Futura-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Futura';
  src: url('../media/fonts/Futura-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}*/

.no-display {
  display: none;
}


@import "colors.scss";
@import "pages/pagetemplate.scss";
@import "pages/newsItem.scss";
@import "pages/newspage.scss";
@import "pages/infosmodalpage.scss";
@import "pages/searchpage.scss";
@import "pages/resultpages.scss";
@import "pages/splashscreenpage.scss";
@import "fonts.scss";

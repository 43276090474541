.search-form {
  margin-top: 24px; 
  padding-top: 0; 
  .md-form {
    position: relative;
    margin-top: .65rem;
    margin-bottom: .65rem;
    font-size: 18px;
  }
  .md-form input[type="text"]:not(.browser-default){
    background-color: #fff;
    border: none;
    border-radius: 3px;
  }
  .md-form .form-control {
    height: 30px;
    padding: 0 3px 0 3px;
    margin: 0 0 0rem 0;
  }
  .search-title{
    color: #fff;
    font-size: 18px;
  }
}

.search-form-container {
  width: 275px;
}

.search-form-header-col {
  width: 75px;
  padding-right: 5px;
}
.search-form-input-col {
  width: 195px;
}

.horizontalpicker-text {
  color: #ffffff;
  font-weight: bold;
  //font-size: 34px;
  letter-spacing: -4px;
  margin-left: -4px;
}

.button-row {
  background: linear-gradient(180deg, $color-verticalpicker-header-top 0%, $color-verticalpicker-header-middlebottom 50%, $color-verticalpicker-header-middlebottom 100%);
  height: 35px;
  line-height: 19px;
}

.font-blue {
  color: $color-blue;
}

.font-black {
  color: $color-black;
}

@media (max-width: 379.98px) {
  body {
    font-size: 12px;
  }
  .modal-shadow .pierItems-picker .boat-img {
    height: 35px;
  }

  .small-sicht {
    padding-left: 15px!important;
    padding-right: 15px!important;
  }
}

@import "../colors.scss";

.template-header {
  position: fixed;
  top:0;
  z-index:1005;
  height: 50px;
  width: 100%;
  max-width: 500px;
  border-bottom: 1px solid $color-grey;
  background-color: $color-blue;
}

.template-body {
  width: 100%;
  max-width: 500px;
}

.template-header-row{
  width: 100%;
}

.template-title-text {
  color: #ffffff;
  font-size: 26px;
}

.resultpage-template-title-text {
  color: #ffffff;
  font-size: 20px;
}

.retour-btn {
  height: 26px;
  width: 58px;
}

.infos-btn {
  height: 26px;
  width: 53px;
}

.nav-left {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
  text-align: left;
  flex: 0 0 18%;
  max-width: 18%;
}

.nav-title {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
  text-align: center;
  flex: 0 0 64%;
  max-width: 64%;
}

.nav-right {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
  text-align: right;
  flex: 0 0 18%;
  max-width: 18%;
}

@media (max-width: 375px) {
  .nav-left {
    flex: 0 0 21%;
    max-width: 21%;
  }
  .nav-title {
    flex: 0 0 58%;
    max-width: 58%;
  }
  .nav-right {
    flex: 0 0 21%;
    max-width: 21%;
  }
}

@media (max-width: 320px) {
  .template-title-text {
    font-size: 20px;
  }
  .resultpage-template-title-text {
    font-size: 20px;
  }
  .nav-left {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
    flex: 0 0 22%;
    max-width: 22%;
  }
  
  .nav-title {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
    flex: 0 0 56%;
    max-width: 56%;
  }
  
  .nav-right {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
    flex: 0 0 22%;
    max-width: 22%;
  }
}

@media (max-width: 575.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) and (max-width: 1499.98px) {
}

$color-blue: #102F5C;
$color-yellow: #E2A95C;
$color-black: #000000;
$color-white: #FFFFFF;

$color-yellow-line: #DDA149;
$color-light-blue-button: #CDE5F0;
$color-light-blue-table: #E5F2F7;
$color-grey: #ADADAD;
$color-light-grey: #EEEEEF;
$color-yellow2: #ECD04B;
$color-link: #0000E4;

$color-verticalpicker-header-top: #A3A3A3;
$color-verticalpicker-header-middlebottom: #666666;

.font-futura-condensed {
    font-family: 'Futura';
    font-stretch: condensed;
    font-size: 15px;
}

.font-500 {
    font-weight: 500;
}

.font-300 {
    font-weight: 300;
}

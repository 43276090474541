.pt-34px {
  padding-top: 34px !important;
}

.pb-17px {
  padding-bottom: 17px !important;
}

.container-height {
  height: calc(100vh - 110px);
}

.padding-left {
  padding-left: 65px;
  padding-right: 65px;
}

.padding-mealpart {
  padding: 34px 40px 34px 40px;
}

.font-16px {
  font-size: 16px !important;
}

.font-18px {
  font-size: 18px !important;
}

.pierdispaly {
    border-bottom: 1px solid $color-grey!important;
    height: 30px;
}
.boatdispaly {
    border-bottom: 1px solid $color-grey!important;
    border-left: 1px solid $color-grey!important;
    height: 30px;
}

.ancor-icon {
  width: auto;
  height: 17px;
  margin-bottom: 2px;
}

.boat-icon {
  width: auto;
  height: 15px;
  margin-bottom: 2px;
}

.btn-opacity {
  opacity: 0.6;
  border-radius: 50%;
}
.btn-none-opacity {
  border-radius: 50%;
}

.horizontalpicker {
    border-bottom: 1px solid $color-grey;
    .m-style-picker {
      display: block;
      overflow: hidden;
      width: 100%;
      text-align: center;
      bottom: 0!important;
      //height: 200px!important;
    }
    .m-style-picker-item {
      font-size: 1rem;
      color: #fff!important;
      height: 40px!important{}
      line-height: 40px!important;
      padding: 0 2px!important;
    }
    div.m-style-picker-mask:nth-child(1){
      background-position: right center!important;
      top: 0px!important;
      left: 0px!important;
      height: 100%!important;
      width: 40px!important;
      background-image: linear-gradient(to right, rgb(9, 44, 100, 0.9), rgba(9, 44, 100, 0))!important;
    }
    div.m-style-picker-mask:nth-child(2){
      background-position: right center!important;
      top: 0px!important;
      left: calc(100% - 50px)!important;
      height: 100%!important;
      width: 40px!important;
      background-image: linear-gradient(to left, rgb(9, 44, 100, 0.9), rgba(9, 44, 100, 0))!important;
    }
    .m-style-picker-indicator-horizontal {
      border-left: none;
      border-right: none;
    }
}

.vl {
  //border-left: 2px solid #c86e42;
  height: 8px;
  position: absolute;
  left: calc(50% - 3px);
  bottom: 6px;
  img {
    height: 8px;
  }
}

.mappart-format {
  width: 100%;
  height: calc(100vh - 170px);
  background-color: #fff;
  //overflow: scroll;
  .pdf-bild {
    min-height: 50vh;
    overflow: hidden;
    padding-top: 30px;
    .search-icon {
      position: absolute;
      top:25px;
      //left: calc(50% - 240px);
      right: 25px;
    }
  }
  .spag-img-component {
    position: absolute;
    left: calc(50% - 250px);
    top: 70px;
    width: 80px;
    height: auto;
    z-index: 1004;
    border-bottom: 1px solid #ffffff!important;
    border-right: 1px solid #ffffff!important;
  }
  .time-text-component {
    font-size: 25px;
    font-weight: bold !important;
  }
  .spag-bild {
    //height: 444px;
    overflow: hidden;
    padding-top: 0px;
  }
  .time-line{
    display: inline-flex;
    height: 50px;
    position: relative;
    z-index: 1003;
  }
  .line-time{
    //display: inline-flex;
    width: 80px;
    position: relative;
    z-index: 1003;
    .pier-text {
      font-size: 20px;
      line-height: 23px;
      font-weight: 300;
    }
    .pier-text-2 {
      font-size: 20px;
      line-height: 23px;
      font-weight: bold!important;
    }
    .pier-text-format {
      display: grid;
      flex-flow: row wrap;
      align-content: center;
      justify-content: right;
    }
  }
  .horizontal-component {
    //width: 2200px;
    display: inline-flex;
  }
  .boat-icon {
    width: 100%;
    height: auto;
  }
  .boat-icon-2 {
    width: 268px;
    height: auto;
  }
}

.news-bold-text {
  font-size: 16px;
  font-weight: bold!important;
}

.restaurant-info-para{
  font-size: 18px;
  font-weight: 300;
}

.additional-info-para {
  font-size: 18px;
  font-weight: 300;
  a {
    text-decoration: underline;
  }
}

.navigation-nav {
  //position: fixed;
  //bottom: 0;
  width: 100%;
  background-color: #d2d8d9;
  //background-color: rgb(9, 44, 100);
  padding-left: calc(50% - 250px);
  padding-right: calc(50% - 250px);
  .navbar {
    padding: 0!important;
    box-shadow: none!important;
    height: 50px;
  }
  .navbar .nav-item .nav-link {
    padding: 0!important;
    height: 50px!important;
  }
  .nav-item {
    .border-right {
      height: 50px!important;
      border-right: 1px solid $color-grey!important;
    }
    width:100%!important;
    .nav-link{
      .active-icon {
        width: auto;
        height: auto;
        max-width: 54px;
        max-height: 26px;
        opacity: 1;
      }
      .inactive-icon {
        width: auto;
        height: auto;
        max-width: 54px;
        max-height: 26px;
        opacity: 1;
      }
      .inactive-icon-overlay {
        width: auto;
        height: auto;
        max-width: 54px;
        max-height: 26px;
        position: absolute;
        //position: relative;
        //left: -20px;
        opacity: 0.7;
      }
      .leman-icon {
        height: 27px !important;
        max-height: 27px !important;
        width: 52px !important;
        max-width: 52px !important;
      }
      .horloge-icon {
        height: 25px !important;
        max-height: 25px !important;
        width: 24px !important;
        max-width: 24px !important;
      }
      .bateau-icon {
        height: 18px !important;
        max-height: 18px !important;
        width: 44px !important;
        max-width: 44px !important;
      }
      .resto-icon {
        height: 28px !important;
        max-height: 28px !important;
        width: 34px !important;
        max-width: 34px !important;
      }
      .gouvernail-icon {
        height: 32px !important;
        max-height: 32px !important;
        width: 31px !important;
        max-width: 31px !important;
      }
    }
  }
  .navbar-nav {
    background-color: rgb(9, 44, 100);
    height: 100%!important;
  }
}

.navigation-feld {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2000;
}

.bold-text {
  font-weight: bold!important;
  //font-size: 15px!important;
}

.bold-text-italic {
  font-style: italic !important;
  font-weight: bold!important;
}

.text-decoration {
  text-decoration: underline!important;
  color: #0000E4!important;
}

.result-boat-name-text {
  font-size: 17px;
  font-weight: 700;
}

.result-pier-name-text {
  font-size: 17px;
  font-weight: 700;
}

@media (max-width: 499.98px) {

  .mappart-format {
    .pdf-bild {
      .search-icon {
        left: auto;

      }
    }
    .spag-img-component {
      left: -1px;
    }
  }
}

@media (max-width: 325px) {
  .mappart-format {
    .time-text-component {
      font-size: 21px;
    }
    .line-time {
      .pier-text {
        font-size: 18px;
        line-height: 21px;
      }
      .pier-text-2 {
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
  .container-height {
    height: calc(100vh - 100px);
  }
  .navigation-nav {
    //position: fixed;
    //bottom: 0;
    width: 100%;
    background-color: rgb(9, 44, 100);
    .navbar {
      padding: 0!important;
      box-shadow: none!important;
      height: 50px;
    }
    .navbar .nav-item .nav-link {
      //padding: 5px 0 0 0!important;
      height: 50px!important;
    }
    .nav-item {
      .border-right {
        height: 50px!important;
        border-right: 1px solid $color-grey!important;
      }
      width:100%!important;
      .nav-link{
        .active-icon {
          width: auto;
          height: auto;
          max-width: 60%;
          max-height: 60%;
          opacity: 1;
        }
        .inactive-icon {
          width: auto;
          height: auto;
          max-width: 60%;
          max-height: 60%;
          opacity: 1;
        }
        .inactive-icon-overlay {
          width: auto;
          height: auto;
          max-width: 12%;
          max-height: 47%;
          opacity: 0.7;
        }
      }
    }
    .navbar-nav {
      height: 100%!important;
    }
  }
  .mappart-format {
    height: calc(100vh - 170px);
    .pdf-bild {
      min-height: 50vh;
      overflow: hidden;
      padding-top: 15px;
      .search-icon {
        position: absolute;
        top:25px;
        //left: calc(50% - 240px);
        right: 25px;
      }
    }
  }
  .iphone-se{
    margin-top: -75px !important;
  }

}

@media (min-width: 325.02px) and (max-width: 395px) {
  .navigation-nav {
    //position: fixed;
    //bottom: 0;
    width: 100%;
    background-color: rgb(9, 44, 100);
    .navbar {
      padding: 0!important;
      box-shadow: none!important;
      height: 50px;
    }
    .navbar .nav-item .nav-link {
      //padding: 5px 0 0 0!important;
      height: 45px!important;
    }
    .nav-item {
      .border-right {
        height: 50px!important;
        border-right: 1px solid $color-grey!important;
      }
      width:100%!important;
      .nav-link{
        .active-icon {
          width: auto;
          height: auto;
          max-width: 60%;
          max-height: 60%;
          opacity: 1;
        }
        .inactive-icon {
          width: auto;
          height: auto;
          max-width: 60%;
          max-height: 60%;
          opacity: 1;
        }
        .inactive-icon-overlay {
          width: auto;
          height: auto;
          max-width: 12%;
          max-height: 47%;
          opacity: 0.7;
        }
      }
    }
    .navbar-nav {
      height: 100%!important;
    }
  }
  .mappart-format {
    .pdf-bild {
      min-height: 50vh;
      overflow: hidden;
      padding-top: 65px;
      .search-icon {
        position: absolute;
        top:25px;
        //left: calc(50% - 240px);
        right: 25px;
      }
    }
    .boat-icon {
      position: relative!important;
      top: -50px!important;
    }
  }

  .boat-name-row {
    position: relative!important;
    top: -50px!important;
  }
}

.boatname {
  font-style: italic;
}

.piername {
  //font-family: 'Times New Roman', Times, serif;
}

@media (min-width: 500px) and (max-width: 767.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) and (max-width: 1499.98px) {
}
